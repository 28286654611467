import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import checkNested from 'check-nested'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    viewInfoDataSelected: state => state.viewInfoDataSelected.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => state.aFieldsOrder,
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    filterSelected: state => ({
      processoSelecionado: state.listviewSelectedProcesso.value,
      separadorSelecionado: state.listviewSelectedSeparador.value,
      hubsSelecionados: state.listviewSelectedHubs.value,
      responsavelSelecionado: state.listviewSelectedResponsavel.value,
      consultorSelecionado: state.listviewSelectedConsultor.value,
      consultorIDSelecionado: state.listviewSelectedConsultorID.value,
      filtroDataSelecionado: state.listviewSelectedDataTipo.value,
      intervaloDataInicioSelecionado: state.listviewSelectedIntervaloDataInicio.value,
      intervaloDataFimSelecionado: state.listviewSelectedIntervaloDataFim.value,
      bancoSelecionado: state.listviewSelectedBanco.value,
      hubsExternosSelecionado: state.listviewSelectedHubsExternos.value,
      faturaComissaoSelecionado: state.listviewSelectedFaturaComissao.value,
      angariacaoAssociadaSelecionado: state.listviewSelectedAngariacaoAssociada.value,
      estadoProcessoSelecionado: state.listviewSelectedEstadoProcesso.value,
      respostaBancoSelecionado: state.listviewSelectedRespostaBanco.value,
      totalFields: state.listviewSelectedFields.value,
    }),
    filterData: state => ({
      listviewFilterSeparadores: state.listviewFilterSeparadores,
      listviewFilterHubs: state.listviewFilterHubs.value,
      listviewFilterResponsavel: state.listviewFilterResponsavel.value,
      listviewFilterBancos: state.listviewFilterBancos.value,
      listviewFilterHubsExternos: state.listviewFilterHubsExternos.value,
      listviewFilterFaturaComissao: state.listviewFilterFaturaComissao,
      listviewFilterAngariacaoAssociada: state.listviewFilterAngariacaoAssociada,
      listviewFilterEstadoProcesso: state.listviewFilterEstadoProcesso,
      listviewFilterRespostaBanco: state.listviewFilterRespostaBanco,
      listviewFilterDatasTipo: state.listviewFilterDatasTipo,
    }),
    filterControl: state => ({
      disableHubsExternos: state.disableHubsExternos.value,
    }),
    filterSelectedTotalFields: state => state.filterSelectedTotalFields.value,
    positionListview: state => state.positionListview,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.listviewSelectedSeparador.value = payload
      state.tabSelectFilter.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.filterSelectedTotalFields.value = 0
      state.listviewSelectedProcesso.value = ''
      state.listviewSelectedHubs.value = ''
      state.listviewSelectedDataTipo.value = ''
      state.listviewSelectedIntervaloDataInicio.value = ''
      state.listviewSelectedIntervaloDataFim.value = ''
      state.listviewSelectedResponsavel.value = ''
      state.listviewSelectedConsultor.value = ''
      state.listviewSelectedConsultorID.value = ''
      state.listviewSelectedBanco.value = ''
      state.listviewSelectedHubsExternos.value = ''
      state.listviewSelectedFaturaComissao.value = ''
      state.listviewSelectedAngariacaoAssociada.value = ''
      state.listviewSelectedEstadoProcesso.value = ''
      state.listviewSelectedRespostaBanco.value = ''
      state.searchTxt.value = ''
    },
    setfSW404_fltHub(state, payload) {
      if (payload === null) {
        state.listviewSelectedHubs.value = ''
      } else {
        state.listviewSelectedHubs.value = payload
        state.disableHubsExternos.value = 1
        if (state.listviewSelectedHubs.value.length > 0) {
          state.listviewSelectedHubs.value.forEach(val => {
            if (val.id === '-1') {
              state.disableHubsExternos.value = 0
            }
          })
        }
      }
    },
    setfSW404_fltResponsavel(state, payload) {
      if (payload === null) {
        state.listviewSelectedResponsavel.value = ''
      } else {
        state.listviewSelectedResponsavel.value = payload
      }
    },
    setfSW404_fltTipoDateSearch(state, payload) {
      if (payload === null) {
        state.listviewSelectedDataTipo.value = ''
        state.listviewSelectedIntervaloDataInicio.value = ''
        state.listviewSelectedIntervaloDataFim.value = ''
      } else {
        state.listviewSelectedDataTipo.value = payload
      }
    },
    setfSW404_fltFaturaComissao(state, payload) {
      if (payload === null) {
        state.listviewSelectedFaturaComissao.value = ''
      } else {
        state.listviewSelectedFaturaComissao.value = payload
      }
    },
    setfSW404_fltExternalHub(state, payload) {
      if (payload === null) {
        state.listviewSelectedHubsExternos.value = ''
      } else {
        state.listviewSelectedHubsExternos.value = payload
      }
    },
    setfSW404_fltBancos(state, payload) {
      if (payload === null) {
        state.listviewSelectedBanco.value = ''
      } else {
        state.listviewSelectedBanco.value = payload
      }
    },
    setIntervaloDataInicio(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataInicio.value = ''
      } else {
        state.listviewSelectedIntervaloDataInicio.value = payload
      }
    },
    setIntervaloDataFim(state, payload) {
      if (payload === null) {
        state.listviewSelectedIntervaloDataFim.value = ''
      } else {
        state.listviewSelectedIntervaloDataFim.value = payload
      }
    },
    setfSW404_fltConsultor(state, payload) {
      if (payload === null) {
        state.listviewSelectedConsultor.value = ''
      } else {
        state.listviewSelectedConsultor.value = payload
      }
    },
    setfSW404_fltAngAssociada(state, payload) {
      if (payload === null) {
        state.listviewSelectedAngariacaoAssociada.value = ''
      } else {
        state.listviewSelectedAngariacaoAssociada.value = payload
      }
    },
    setfSW404_fltEstadoProcesso(state, payload) {
      if (payload === null) {
        state.listviewSelectedEstadoProcesso.value = ''
      } else {
        state.listviewSelectedEstadoProcesso.value = payload
      }
    },
    setfSW404_fltRespostaBanco(state, payload) {
      if (payload === null) {
        state.listviewSelectedRespostaBanco.value = ''
      } else {
        state.listviewSelectedRespostaBanco.value = payload
      }
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              // Verifica se tem o tabulador selecionado, senao fica perde as preferencias todas
              let bSkipLoadPreferencesListview = false

              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    if (field.key === 'tabSelectFilter') {
                      if (field.value.value === '') {
                        bSkipLoadPreferencesListview = true
                      }
                    }
                  }
                }
              })

              if (bSkipLoadPreferencesListview === false) {
                stateCustom.forEach(field => {
                  if (('key' in field) && ('value' in field)) {
                    if (state.statesToLocalStorage.includes(field.key)) {
                      Object.assign(state[field.key], field.value)
                    }
                  }
                })
              }
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
  },
  actions: {
    // Carregar listagem
    fetchListView({
      state, commit, dispatch,
    }, payload) {
      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      if (state.listviewSelectedSeparador.value > 0) {
        listviewFilterParams.append('sw404s07', state.listviewSelectedSeparador.value)
      }

      if (state.listviewSelectedHubs.value.length > 0) {
        state.listviewSelectedHubs.value.forEach(val => {
          listviewFilterParams.append('fSW404_fltHub[]', Buffer.from(val.id).toString('base64'))
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedResponsavel.value !== '' && state.listviewSelectedResponsavel.value !== null) {
        listviewFilterParams.append('fSW404_fltResponsavel', state.listviewSelectedResponsavel.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedConsultor.value !== '' && state.listviewSelectedConsultor.value !== null) {
        listviewFilterParams.append('fSW404_fltConsultor', state.listviewSelectedConsultor.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedDataTipo.value !== '' && state.listviewSelectedDataTipo.value !== null) {
        listviewFilterParams.append('fSW404_fltTipoDateSearch', state.listviewSelectedDataTipo.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedIntervaloDataInicio.value !== '' && state.listviewSelectedIntervaloDataFim.value !== '') {
        listviewFilterParams.append('fSW404_fltDateInterval', `${state.listviewSelectedIntervaloDataInicio.value} - ${state.listviewSelectedIntervaloDataFim.value}`)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedBanco.value.length > 0) {
        state.listviewSelectedBanco.value.forEach(val => {
          listviewFilterParams.append('fSW404_fltBancos[]', val.sw129s01)
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedHubsExternos.value.length > 0) {
        state.listviewSelectedHubsExternos.value.forEach(val => {
          listviewFilterParams.append('fSW404_fltExternalHub[]', Buffer.from(val.id).toString('base64'))
        })
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedFaturaComissao.value !== '' && state.listviewSelectedFaturaComissao.value !== null) {
        listviewFilterParams.append('fSW404_fltFaturaComissao', state.listviewSelectedFaturaComissao.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedAngariacaoAssociada.value !== '' && state.listviewSelectedAngariacaoAssociada.value !== null) {
        listviewFilterParams.append('fSW404_fltAngAssociada', state.listviewSelectedAngariacaoAssociada.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedEstadoProcesso.value !== '' && state.listviewSelectedEstadoProcesso.value !== null) {
        listviewFilterParams.append('fSW404_fltEstadoProcesso', state.listviewSelectedEstadoProcesso.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewSelectedRespostaBanco.value !== '' && state.listviewSelectedRespostaBanco.value !== null) {
        listviewFilterParams.append('fSW404_fltRespostaBanco', state.listviewSelectedRespostaBanco.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        listviewFilterParams.append('order[0][column]', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('order[0][dir]', state.listviewOrderBy.value.sort)
      }

      if (state.searchTxt.value !== '' && state.searchTxt.value !== null) {
        listviewFilterParams.append('fSearch', state.searchTxt.value)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('saveStates')

      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}creditprocess/loadList`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ commit }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}creditprocess/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                if (typeof response.data.data.aMoradasAvailable !== 'undefined') {
                  const aMoradasAvailable = []

                  Object.entries(response.data.data.aMoradasAvailable).forEach(element => {
                    aMoradasAvailable.push({ id: element[0], desc: element[1] })
                  })

                  aMoradasAvailable.push({ id: '-1', desc: i18n.t('HUBs Externos') })

                  commit('setFilterData', { listviewFilterHubs: aMoradasAvailable })
                }

                if (typeof response.data.data.aExternalMoradas !== 'undefined') {
                  const aExternalMoradas = []

                  Object.entries(response.data.data.aExternalMoradas).forEach(element => {
                    aExternalMoradas.push({ id: element.sw002s01, desc: element.zona })
                  })

                  commit('setFilterData', { listviewFilterHubsExternos: response.data.data.aExternalMoradas })
                }

                if (typeof response.data.data.aResponsaveis !== 'undefined') {
                  const aResponsaveis = []

                  Object.entries(response.data.data.aResponsaveis).forEach(element => {
                    aResponsaveis.push({ id: element[0], desc: element[1] })
                  })

                  commit('setFilterData', { listviewFilterResponsavel: aResponsaveis })
                }

                commit('setFilterData', { listviewFilterBancos: response.data.data.fSW404_fltBancos_list })
              }

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Carrega os filtros conforme o separador ativo
    async showFiltersListview({ state, commit, dispatch }) {
      if (state.filterDataTabsListview.value.length > 0) {
        const oTabsListviewActive = state.filterDataTabsListview.value.filter(oTab => oTab.show === true)

        try {
          if (oTabsListviewActive[0].key) {
            commit('setTabSelectFilter', oTabsListviewActive[0].key)
            dispatch('showFiltersListviewByTabSelected')
          }
        } catch {
        //
        }
      }
    },

    showFiltersListviewByTabSelected({ commit }) {
      commit('setShowFiltersListviewByTabSelected')
      commit('setFilterData', { filterDataLoad: true })
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      return dispatch('fetchListView')
    },

    setLeadIDSelected({ commit }, idLead) {
      commit('setSelectedLeadsID', idLead)
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Escolher responsavel
    listviewFilterUserResponsable({ commit, dispatch }, oResponsableSelected) {
      if ((oResponsableSelected !== null) && (!!oResponsableSelected.id)) {
        let oResponsable = {}
        oResponsable = oResponsableSelected
        oResponsable.id = btoa(oResponsableSelected.id)

        commit('setUserResponsable', oResponsable)
      } else {
        commit('setUserResponsable', oResponsableSelected)
      }

      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Alterar separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      // commit('resetAllFilterSelected')
      // commit('setShowFiltersListviewByTabSelected')
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Partilhados / Referenciados
    listviewFilterTabField({ commit, dispatch }, tab) {
      if (tab === 'leads_partilhadas') {
        commit('setFilterSelected', { filterSelectedPartilhados: 'all' })
        commit('setFilterSelected', { filterSelectedReferenciados: '' })
      }
      if (tab === 'leads_referenciados') {
        commit('setFilterSelected', { filterSelectedPartilhados: '' })
        commit('setFilterSelected', { filterSelectedReferenciados: 'all' })
      }
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Filtro geral dos blocos
    listviewFilterGeral({ state, dispatch }, params) {
      try {
        const nameParam = Object.keys(params)[0]
        const valueParam = params[nameParam]

        const indexItem = state[nameParam].value.indexOf(valueParam)
        if (indexItem === -1) {
          state[nameParam].value.push(valueParam)
        } else {
          state[nameParam].value.splice(indexItem, 1)
        }

        if (state[nameParam].value.includes('all')) {
          const indexItemAll = state[nameParam].value.indexOf('all')
          state[nameParam].value.splice(indexItemAll, 1)
        }

        if (state[nameParam].value.length === 0) {
          state[nameParam].value.push('all')
        }

        if (valueParam === 'all') {
          state[nameParam].value = []
          state[nameParam].value.push('all')
        }

        if (nameParam === 'filterSelectedTiposClienteLead') {
          if (state[nameParam].value.includes('2') || state[nameParam].value.includes('4')) {
            state.filterSelectedShowStatusComprador.value = true
          } else {
            state.filterSelectedShowStatusComprador.value = false
            state.filterSelectedEstadosLeadComprador.value = []
            state.filterSelectedEstadosLeadComprador.value.push('all')
          }

          if (state[nameParam].value.includes('1') || state[nameParam].value.includes('3')) {
            state.filterSelectedShowStatusVendedor.value = true
          } else {
            state.filterSelectedShowStatusVendedor.value = false
            state.filterSelectedEstadosLeadVendedor.value = []
            state.filterSelectedEstadosLeadVendedor.value.push('all')
          }
        }

        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    selectFilterOptions({ commit, dispatch }, params) {
      if (params[0] === 'fSW404_fltHub') {
        commit('setfSW404_fltHub', params[1])
      } else if (params[0] === 'fSW404_fltResponsavel') {
        commit('setfSW404_fltResponsavel', params[1])
      } else if (params[0] === 'fSW404_fltExternalHub') {
        commit('setfSW404_fltExternalHub', params[1])
      } else if (params[0] === 'fSW404_fltTipoDateSearch') {
        commit('setfSW404_fltTipoDateSearch', params[1])
      } else if (params[0] === 'fSW404_fltAngAssociada') {
        commit('setfSW404_fltAngAssociada', params[1])
      } else if (params[0] === 'fSW404_fltEstadoProcesso') {
        commit('setfSW404_fltEstadoProcesso', params[1])
      } else if (params[0] === 'fSW404_fltBancos') {
        commit('setfSW404_fltBancos', params[1])
      } else if (params[0] === 'fSW404_fltConsultor') {
        commit('setfSW404_fltConsultor', params[1])
      } else if (params[0] === 'fSW404_fltRespostaBanco') {
        commit('setfSW404_fltRespostaBanco', params[1])
      } else if (params[0] === 'fSW404_fltFaturaComissao') {
        commit('setfSW404_fltFaturaComissao', params[1])
      }

      return dispatch('fetchListView')
    },

    selectFilterIntervaloDataInicio({ commit, dispatch }, value) {
      commit('setIntervaloDataInicio', value)
      dispatch('fetchListView')
    },

    selectFilterIntervaloDataFim({ commit, dispatch }, value) {
      commit('setIntervaloDataFim', value)
      dispatch('fetchListView')
    },

    getInfoProcess(state, payload) {
      const oParamsFilter = new FormData()
      oParamsFilter.append('sw404s01', payload.sw404s01 || '')

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}creditprocess/loadList`, oParamsFilter)
          .then(response => {
            try {
              if (checkNested(response, 'data.data')) {
                const infoProcess = response.data.data.shift()
                if (infoProcess !== undefined) {
                  resolve(infoProcess)
                } else {
                  reject(new Error(i18n.t('Problema ao obter a informação do processo de crédito')))
                }
              }
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao obter a informação do processo de crédito')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao obter a informação do processo de crédito')))
            }
          })
      })
    },

  },
}
