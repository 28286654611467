<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
    >
      <b-media-body>
        <div class="mail-details">
          <b-container
            fluid
          >
            <b-row>
              <b-col md="12">
                <h5
                  v-if="row[1]"
                  class=""
                >
                  {{ row[1] }}

                  <b-badge
                    v-if="row[9] === 2"
                    variant="light-primary"
                  >
                    {{ row[8] }}
                  </b-badge>
                  <b-badge
                    v-if="row[9] === 4"
                    variant="light-success"
                  >
                    {{ row[8] }}
                  </b-badge>
                  <b-badge
                    v-if="row[9] === 5"
                    variant="light-danger"
                  >
                    {{ row[8] }}
                  </b-badge>
                  <b-badge
                    v-if="row[9] === 1"
                    variant="light-warning"
                  >
                    {{ row[8] }}
                  </b-badge>
                  <b-badge
                    v-if="row[9] === 3"
                    variant="light-info"
                  >
                    {{ row[8] }}
                  </b-badge>

                  <b-badge
                    v-if="row[10] === 1"
                    variant="light-dark"
                    class="ml-50"
                  >
                    {{ $t('Transferido') }}
                  </b-badge>
                </h5>

              </b-col>
            </b-row>
            <b-row>
              <b-col md="3 mt-1">
                <span
                  v-if="row[2]"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row[2] }} <small v-if="row[3]">({{ row[3] }})</small>
                </span>
                <br>
                <span
                  v-if="row[2]"
                >
                  <feather-icon
                    icon="HomeIcon"
                  />
                  {{ row[4] }}
                </span>
                <div
                  v-if="row[2]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Utilizador') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="3 mt-1"
              >
                <span
                  v-if="row[5]"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row[5] }}
                </span>
                <br>
                <div
                  v-if="row[5]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $tc('Lead', 1) }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[6]"
                >
                  <feather-icon
                    icon="UserIcon"
                  />
                  {{ row[6] }}
                </span>
                <br>
                <div
                  v-if="row[6]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Responsável') }}
                  </p>
                </div>
              </b-col>
              <b-col md="2 mt-1">
                <span
                  v-if="row[7]"
                >
                  {{ formatPrice(row[7]) }}
                </span>
                <br>
                <div
                  v-if="row[7]"
                  class="mail-message"
                >
                  <p class="mb-0">
                    {{ $t('Valor financiamento') }}
                  </p>
                </div>
              </b-col>
              <b-col
                md="2"
                class="mt-1"
              >
                <div class="align-items-center justify-content-start">
                  <b-button
                    variant="outline-primary"
                    @click.stop.prevent="openModalDetailByRow(row)"
                  >
                    <span class="align-middle">{{ $t('Ver detalhes') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div class="mail-items" />
        </div>

        <!-- Tipo de cliente -->

        <!-- Itens de alerta -->
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaBody, VBTooltip, BRow, BCol, BContainer, BButton, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BMedia,
    BMediaBody,
    BRow,
    BCol,
    BContainer,
    BButton,
    BBadge,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    openModalDetailByRow: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('financial_processoscredito', ['listviewData', 'filterSelected', 'labelData']),
  },
  methods: {},
}
</script>
