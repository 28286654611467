import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({

  // variaveis dos selecionados
  listviewSelectedProcesso: ref(0),
  listviewSelectedSeparador: ref(1),
  listviewSelectedHubs: ref([]),
  listviewSelectedResponsavel: ref(''),
  listviewSelectedConsultor: ref(''),
  listviewSelectedConsultorID: ref(''),
  listviewSelectedDataTipo: ref(''),
  listviewSelectedIntervaloDataInicio: ref(''),
  listviewSelectedIntervaloDataFim: ref(''),
  listviewSelectedBanco: ref([]),
  listviewSelectedHubsExternos: ref([]),
  listviewSelectedFaturaComissao: ref(''),
  listviewSelectedAngariacaoAssociada: ref(''),
  listviewSelectedEstadoProcesso: ref(''),
  listviewSelectedRespostaBanco: ref(''),

  // variaveis dos filtros
  listviewFilterSeparadores: ([
    {
      key: 1,
      text: i18n.t('Novos'),
      show: true,
    },
    {
      key: 3,
      text: i18n.t('Em curso'),
      show: true,
    },
    {
      key: 2,
      text: i18n.t('Pré-concluídos'),
      show: true,
    },
    {
      key: 4,
      text: i18n.t('Concluídos'),
      show: true,
    },
    {
      key: 5,
      text: i18n.t('Cancelados'),
      show: true,
    },
    {
      key: 0,
      text: i18n.t('Todos'),
      show: true,
    },
  ]),
  listviewFilterHubs: ref([]),
  listviewFilterResponsavel: ref([]),
  listviewFilterBancos: ref([]),
  listviewFilterHubsExternos: ref([]),
  listviewFilterFaturaComissao: ([
    {
      id: '1',
      desc: i18n.t('Pago'),
    },
    {
      id: '2',
      desc: i18n.t('Não pago'),
    },
  ]),
  listviewFilterAngariacaoAssociada: ([
    {
      id: '1',
      desc: i18n.t('Sim'),
    },
    {
      id: '2',
      desc: i18n.t('Não'),
    },
  ]),
  listviewFilterEstadoProcesso: ([
    {
      id: '1',
      desc: i18n.t('Submetido'),
    },
    {
      id: '2',
      desc: i18n.t('Com dados'),
    },
    {
      id: '3',
      desc: i18n.t('Sem dados'),
    },
  ]),
  listviewFilterRespostaBanco: ([
    {
      id: '2',
      desc: i18n.t('Submetido ao banco'),
    },
    {
      id: '3',
      desc: i18n.t('Pré-aprovado pelo banco'),
    },
    {
      id: '6',
      desc: i18n.t('Em aprovação pelo banco'),
    },
    {
      id: '4',
      desc: i18n.t('Aprovado pelo banco'),
    },
    {
      id: '5',
      desc: i18n.t('Reprovado pelo banco'),
    },
  ]),
  listviewFilterDatasTipo: ([
    {
      id: 'dataregisto',
      desc: i18n.t('Data de registo'),
    },
    {
      id: 'datasubmissao',
      desc: i18n.t('Data de submissão'),
    },
    {
      id: 'dataescritura',
      desc: i18n.t('Data de escritura'),
    },
  ]),

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  viewInfoDataSelected: ref({}),
  tabSelectFilter: ref(''),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: '2',
    sort: 'desc',
  }),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('ID Processo, Nome do Lead e Nome Utilizador'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  aFieldsOrder: [
    {
      name: '1',
      txt: i18n.t('Ordenar pelo ID Processo'),
    },
    {
      name: '2',
      txt: i18n.t('Ordenar pela data de registo'),
    },
    {
      name: '3',
      txt: i18n.t('Ordenar pelo nome do Lead'),
    },
    {
      name: '4',
      txt: i18n.t('Ordenar pela angariação'),
    },
    {
      name: '5',
      txt: i18n.t('Ordenar pelo valor de financiamento'),
    },
    {
      name: '6',
      txt: i18n.t('Ordenar por aceite pelo cliente'),
    },
    {
      name: '7',
      txt: i18n.t('Ordenar pelo responsável'),
    },
    {
      name: '8',
      txt: i18n.t('Ordenar pelo utilizador'),
    },
  ],
  filterSelectedTotalFields: ref(0),
  listviewSelectedFields: ref(0),
  disableHubsExternos: ref(1),

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'listviewSelectedProcesso',
    'listviewSelectedSeparador',
    'listviewSelectedHubs',
    'listviewSelectedResponsavel',
    'listviewSelectedConsultor',
    'listviewSelectedConsultorID',
    'listviewSelectedDataTipo',
    'listviewSelectedIntervaloDataInicio',
    'listviewSelectedIntervaloDataFim',
    'listviewSelectedBanco',
    'listviewSelectedHubsExternos',
    'listviewSelectedFaturaComissao',
    'listviewSelectedAngariacaoAssociada',
    'listviewSelectedEstadoProcesso',
    'listviewSelectedRespostaBanco',
    'listviewSelectedFields',
    'disableHubsExternos',
    'filterSelectedTotalFields',
    'tabSelectFilter',
    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'positionListview',
  ],

})

export default defaultState
